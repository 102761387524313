import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ContentImageTile from '~2-components/ContentImageTile/ContentImageTile';
import TileContainer from '~2-components/TileContainer/TileContainer';

const contentImageTileEg = `import ContentImageTile from '~2-components/ContentImageTile/ContentImageTile';

<ContentImageTile
    imgUrl='' // Required
    imgAlt='' // Required
    title='' // Required
    blurb='' // Required
    linkText='' // Required
    linkUrl='' // Required
/>
`;

const SgContentImageTile = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allContentImageTiles = useStaticQuery(
        graphql`
            query SGContentImageTiles {
                allKontentItemContentImageTile {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
        `);

    const allTiles = allContentImageTiles.allKontentItemContentImageTile.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Content image Tile</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={contentImageTileEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="two_columns"
                    >
                        <ContentImageTile
                            imgUrl="https://source.unsplash.com/featured/740x420?people"
                            imgAlt="Image alt text"
                            title="Partnerships"
                            blurb="Laudem et harum quidem se oratio, tua praesertim, qui dolorem ipsum per se. In quo ignorare vos arbitrer, sed et quasi naturalem atque natum sit, aspernatur."
                            linkText="Our Partners"
                            linkUrl="partnerships"
                        />
                        <ContentImageTile
                            imgUrl="https://source.unsplash.com/featured/740x420?people"
                            imgAlt="Image alt text"
                            title="Careers"
                            blurb="Laudem et harum quidem se oratio, tua praesertim, qui dolorem ipsum per se. In quo ignorare vos arbitrer, sed et quasi naturalem atque natum sit, aspernatur."
                            linkText="Current Opportunities"
                            linkUrl="current-opportunities"
                        />
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All live image tiles in Kontent</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        tiles={allTiles}
                        columns="two_columns"
                    />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgContentImageTile;
